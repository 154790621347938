<template>
  <div id="classify" class="container">
    <div class="row">
      <div class="col-lg-3 order-2 order-lg-1 classify-list-da">
        <div class="torress-categories_title">
          <h5>{{ $i18n.CLASSIFY_Categories }}</h5>
        </div>

        <el-tree empty-text="..." :data="category" :props="defaultProps" @node-click="handleNodeClick"></el-tree>

        <!--  <div class="sidebar-categories_menu">
          <ul>
            <li
              class="has-sub"
              :class="{ active: classifyId == item.categoryId }"
              v-for="(item, idx) in category"
              @mouseenter="categoryHover(item.sons && item.sons.length, idx)"
              @mouseleave="categoryHover(0)"
            >
              <router-link :to="`/classify?id=${item.categoryId}`">
                {{ item.categoryName | zxby }}<i v-if="item.sons && item.sons.length" class="ion-chevron-right"></i>
              </router-link>
            </li>
          </ul>
        </div> -->
      </div>
      <div class="col-lg-9 order-1 order-lg-2">
        <div
          v-show="openCategory"
          class="category-child"
          @mouseenter="onCategoryShow(1)"
          @mouseleave="onCategoryShow(0)"
        >
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-6" v-for="(item,index) in categoryChild" :key="index">
              <router-link tag="div" class="title" :to="`/classify?id=${item.categoryId}`">
                {{ item.categoryName }}
              </router-link>
              <ul>
                <li v-for="(child,index2) in item.sons" :key="index2">
                  <router-link :to="`/classify?id=${child.categoryId}`">{{ child.categoryName }}</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row classify-loading">
          <loading :show="listLoading" />
          <div class="col-lg-3 col-md-4 col-sm-6 col-xs-6 lists-item" v-for="(list,index) in datas" :key="index">
            <product-box :list="list" />
          </div>
          <div class="col-sm-12" v-if="datas.length < 1">
            <div style="padding: 20px">{{ $i18n.NO_CONETNT }}</div>
          </div>
        </div>
        <page-component :page-config="pageConfigTotal" @changeCurrentPage="changePage"></page-component>
      </div>
    </div>
  </div>
</template>
<script>
import { categoryList, getGoodsList } from '@/api'
import pageComponent from '@/components/page'
import loading from '@/views/public/loading.vue'
import productBox from '@/views/public/productBox'
import { mapState } from 'vuex'
import { zxby } from '@/filters'

export default {
  data() {
    return {
      defaultProps: {
        children: 'sons',
        label(data) {
          return zxby(data.categoryName)
        }
      },
      id: 0,
      loading: 0,
      categoryIndex: 0,
      openCategory: false,
      datas: [],
      pageConfigTotal: {},
      query: {},
      listLoading: true
    }
  },
  components: {
    loading,
    pageComponent,
    productBox
  },
 
  watch: {
    $route({ query }, from) {
      // console.log('query', query, from.query.id)
      let params = {}
      if (query.id !== from.query.id || query.tags !== from.query.tags) {
        params.categoryId = query.id
        params.page = 1
      } else {
        params.page = query.page || 1
      }
      if (query.tags) params.keyword = query.tags
      this.openCategory = false
      this.query = params
      this.getGoodsList(true)
    }
  },
  computed: {
    ...mapState(['category']),
    classifyId() {
      const { id = 0 } = this.$route.query
      return id
    },
    categoryChild() {
      // let data = this.category[this.categoryIndex]
      // return data && data.sons
      return []
    },

    cagegoryParams() {
      return {
        order: 'onsaleDesc',
        page: 1,
        pageLimit: 9,
        categoryId: 0
      }
    }
    /*    pageConfigTotal() {
          // return {}
          let comment = this.datas
          return {
            total: comment.pageLimit * comment.totalPages,
            pageSize: comment.pageLimit,
            pageNo: comment.page
          }
        }*/
  },
  created() {
    let query = this.$route.query
    this.query = {
      page: query.page || 1,
      categoryId: query.id,
      keyword: query.tags
    }
    // this.getCategory()
    this.$store.dispatch('getCategoryList')

    this.getGoodsList()

    setInterval(() => {}, 1000)
  },
  mounted() {
    this.$nextTick(() => {
      this.$root.$el.addEventListener('scroll', this.onScroll)
    })
  },
  methods: {
    handleNodeClick(e) {
      // `/classify?id=${item.categoryId}`
      // console.log(e)
      this.$router.push({ query: { id: e.categoryId } })
    },
    onScroll(e) {
      // console.log(e, this.$root.$el.scrollTop)
      this.$root.$el.scrollTop
    },
    changePage(e) {
      const query = { ...this.$route.query }
      query.page = e
      // console.log(query, this.$route.query)

      this.$router.push({ query })
    },
    getGoodsList(renew) {
      this.listLoading = true
      // 返回顶部
      // this.$root.$el.scrollTop = 0

      this.$nextTick(() => {
        // document.body.scrollTop = 0
      })

      const { id } = this.$route.query

      getGoodsList({ ...this.$route.query, categoryId: id }).then(res => {
            
        let data = res.data.data
        this.listLoading = false

        this.pageConfigTotal = {
          total: data.pageLimit * data.totalPages,
          pageSize: data.pageLimit,
          pageNo: (this.$route.query.page || 1) * 1
        }
        // console.log(this.pageConfigTotal)

        this.datas = data.list
        return

        if (renew) {
          this.datas = data.list
        } else {
          this.datas = this.datas.concat(data.list)
        }
      })
         
    },
    onCategoryShow(isIn) {
      if (isIn) {
        this.openCategory = true
        if (this.timeoutIsIn) {
          clearTimeout(this.timeoutIsIn)
        }
      } else {
        this.timeoutIsIn = setTimeout(() => {
          this.openCategory = false
        }, 500)
      }
    },
    categoryHover(isIn, idx) {
      //console.log('isIn', isIn)
      if (isIn) {
        this.onCategoryShow(true)
        this.categoryIndex = idx
      } else {
        this.onCategoryShow(false)
      }
    },
    getCategory() {
      this.$store.dispatch('getCategoryList', true)

      /* this.$store.dispatch('setAppLoading', true)

      let p = { type: 'children' }
      categoryList(p)
        .then(res => {
          this.$store.dispatch('setAppLoading', false)

          if (res.data.status === 'SUCCESSS') {
            this.loading = 1
            this.category = res.data.data[0].sons[0].sons
          } else {
            this.loading = -1
          }
        })
        .catch(err => {
          this.$store.dispatch('setAppLoading', false)
        })*/
    }
  }
}
</script>
<style lang="less">
@primary-color: #fed100;
@border-color: #ebebeb;
@bg-nobel_color: #999999;

#classify {
  .classify-loading {
    position: relative;

    .loading {
      position: absolute;
      background-color: rgba(255, 255, 255, 0.6);
      z-index: 81;

      .middle {
        // top: 200px;
      }
    }

    .lists-item {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  .category-child {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: auto;
    background-color: #fff;
    padding: 20px;
    z-index: 9999;
    box-shadow: 5px 0 5px rgba(169, 169, 169, 0.3);
    min-height: 500px;

    .title {
      font-weight: bold;
      cursor: pointer;
      margin-bottom: 10px;
      margin-top: 50px;
    }

    li {
      font-size: 14px;
      padding: 3px 0;
    }
  }

  .product-box {
    overflow: hidden;
    text-align: center;
    margin-top: 30px;

    .product-name {
      margin-bottom: 0;
      font-weight: 400 !important;
      padding-bottom: 15px;
      font-size: 17px;
    }

    .new-price {
      margin-right: 4px;
    }
  }

  .torress-categories_title {
    border-bottom: 1px solid @border-color;
    position: relative;
    padding-top: 20px;
    margin-bottom: 20px;

    h5 {
      margin-bottom: 0;
      padding-bottom: 25px;
      font-weight: 600;
    }
  }

  .sidebar-categories_menu {
    ul {
      li {
        cursor: pointer;

        &:first-child {
          padding-top: 20px;
        }

        &:last-child {
          > a {
            padding-bottom: 0;
          }
        }

        &.has-sub {
          &.active {
            a {
              color: @primary-color;
            }
          }
          ul {
            > li {
              &:first-child {
                padding-top: 0;
              }
            }
          }
        }

        &:hover {
          > a {
            color: @primary-color;

            > i {
              color: @primary-color;
            }
          }
        }

        > ul {
          display: none;

          li {
            a {
              display: inline-block;
              padding-bottom: 20px;
              padding-left: 15px;
              color: @bg-nobel_color;
            }

            &:last-child {
              > a {
                padding-bottom: 25px;
              }
            }
          }
        }

        > a {
          display: block;
          padding-bottom: 20px;
          position: relative;
          line-height: 1;

          > i {
            font-size: 16px;
            position: absolute;
            right: 0;
            top: 0;
            transform: rotate(0deg);
            color: @bg-nobel_color;
          }
        }

        &.open {
          > a {
            > i {
              transform: rotate(90deg);
            }
          }
        }
      }
    }
  }

  /*     .sidebar-categories_menu {
    li {
        cursor: pointer;

        &:first-child {
            padding-top: 20px;
        }

        &.has-sub ul>li:first-child {
            padding-top: 0;
        }

        &.open > a > i {
            transform: rotate(90deg);
        }

        >ul {
            display: none;

            li {
                a {
                    display: inline-block;
                    padding-bottom: 20px;
                    padding-left: 15px;
                    color: #999999;
                }
            }
        }

        >a {
            display: block;
            padding-bottom: 20px;
            position: relative;
            line-height: 1;

            >i {
                font-size: 16px;
                position: absolute;
                right: 0;
                top: 0;
                -webkit-transform: rotate(0deg);
                -ms-transform: rotate(0deg);
                transform: rotate(0deg);
                color: #999999;
            }
        }
    }

} */
}
</style>
